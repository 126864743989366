/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.appFooterDefault {
  padding: 10px 0px 0px 0px;
  text-align: center;
}
.appFooterDefault .links {
  margin-bottom: 8px;
}
.appFooterDefault .links a {
  color: #020a12;
  transition: all 0.3s;
}
.appFooterDefault .links a:not(:last-child) {
  margin-right: 40px;
}
.appFooterDefault .links a:hover {
  color: #020a12;
}
.appFooterDefault .copyright {
  color: #020a12;
  font-size: 0.7rem;
}
.appFooter {
  padding: 10px 10px;
  text-align: center;
}
.appFooter .links {
  margin-bottom: 8px;
}
.appFooter .links a {
  color: #020a12;
  transition: all 0.3s;
}
.appFooter .links a:not(:last-child) {
  margin-right: 40px;
}
.appFooter .links a:hover {
  color: #020a12;
}
.appFooter .copyright {
  color: #020a12;
  font-size: 0.7rem;
}
.baseTable :global .ant-table-pagination {
  margin-top: 24px;
}
.baseTable .tableAlert {
  margin-bottom: 16px;
}
.bcContainer {
  background: #fff;
  padding: 16px 32px 0 24px;
  border-bottom: 1px solid #e8e8e8;
}
.bcContainer .breadcrumb {
  margin-bottom: 16px;
}
.bcContainerwithoutBackground {
  background: transparent;
  padding: 80px 32px 0 24px;
  border-bottom: 1px solid #e8e8e8;
}
.bcContainerwithoutBackground .breadcrumb {
  margin-bottom: 16px;
}
.bcContainerwithHeader {
  background: #fff;
  padding: 80px 32px 0 24px;
  border-bottom: 1px solid #e8e8e8;
}
.bcContainerwithHeader .breadcrumb {
  margin-bottom: 16px;
}
.bcContainerwithoutBreadcrumb {
  margin-top: 64px;
}
.bcContainerwithBreadcrumb {
  margin-top: 0px;
}
.chatList {
  padding: 10px;
}
.currentUser {
  text-align: right;
}
.SendMessageButton {
  margin-top: 20px;
  position: absolute;
  right: -20px;
}
.messageDetail :global .ant-card-meta-description {
  padding: 10px !important;
  background-color: #183b94 !important;
  color: #fff !important;
  border-radius: 2px !important;
}
.messageDetail :global .ant-card-meta-title {
  color: #a0a0a0 !important;
}
.enterText {
  border-top: 1px solid #e8e8e8;
}
.enterText :global .ant-input {
  border: 0px !important;
}
.enterText :global .ant-input:focus {
  box-shadow: 0 0 0 0px rgba(21, 54, 126, 0.2) !important;
  /* no unit */
}
.enterText :global .ant-form {
  margin-left: 17px !important;
}
.enterTextLayoutSecond :global .ant-input {
  border-radius: 50px;
}
.enterTextLayoutSecond :global .ant-input:focus {
  box-shadow: 0 0 0 0px rgba(21, 54, 126, 0.2) !important;
  /* no unit */
}
.enterTextLayoutSecond :global .ant-form {
  margin: 18px !important;
}
.msgBox {
  width: 90% !important;
}
.msgBox :global .ant-form-item-control-wrapper {
  width: 100% !important;
}
.msgBoxLayoutSecond {
  width: 100% !important;
}
.msgBoxLayoutSecond :global .ant-form-item-control-wrapper {
  width: 100% !important;
}
.msgBoxLayoutSecond :global .ant-input-affix-wrapper {
  height: 40px;
}
.chatSearch {
  width: 80%;
}
.chatSearch :global .ant-input {
  border: 0px !important;
  /* no unit */
}
.chatSearch :global .ant-input:focus {
  box-shadow: 0 0 0 0px rgba(21, 54, 126, 0.2) !important;
  /* no unit */
}
.chatSearchLayoutSecond {
  width: 100%;
}
.chatSearchLayoutSecond :global .ant-input {
  border: 0px !important;
  /* no unit */
}
.chatSearchLayoutSecond :global .ant-input:focus {
  box-shadow: 0 0 0 0px rgba(21, 54, 126, 0.2) !important;
  /* no unit */
}
.chatRoomList {
  cursor: pointer;
}
.chatRoomList :global .ant-card-body {
  padding: 0px 10px !important;
  /* no unit */
}
.chatRoomList :global .ant-divider-horizontal {
  margin: 13px 0 !important;
}
.mainChatBox {
  border-left: 1px solid #e8e8e8 !important;
  border: 0px;
  /* no unit */
}
.mainChatBox :global .ant-card-body {
  border: #fff !important;
  padding: 0px 0px !important;
  /* no unit */
}
.mainChatBox :global .ant-card-bordered {
  border: 0px !important;
  /* no unit */
}
.mainChatBoxLayoutSecond {
  border-left: 1px solid #e8e8e8 !important;
  border: 0px;
  /* no unit */
  background-color: #f0f2f5;
}
.mainChatBoxLayoutSecond :global .ant-card-head {
  background-color: #00375B;
}
.mainChatBoxLayoutSecond :global .ant-card-body {
  border: #fff !important;
  padding: 0px 0px !important;
  /* no unit */
  background-color: #f0f2f5;
}
.mainChatBoxLayoutSecond :global .ant-card-bordered {
  border: 0px !important;
  /* no unit */
  background-color: #f0f2f5;
}
.mainChatList :global .ant-card-body {
  padding: 1px !important;
}
.mainChatList :global .ant-card-head-title {
  overflow: visible !important;
}
.composeMsg {
  padding: 20px 10px 0px 10px;
  margin-bottom: 22px;
  border-top: 1px solid #e8e8e8;
}
@media screen and (max-width: 992px) {
  .tableListForm :global(.ant-form-item) {
    margin-right: 24px;
  }
}
@media screen and (max-width: 768px) {
  .tableListForm :global(.ant-form-item) {
    margin-right: 8px;
  }
}
.selectChat {
  background-color: #183b94;
}
.chatCover {
  width: 350px;
  margin: 0 auto;
}
.chatCover .chatForm {
  max-width: 350px;
}
.swatchColor {
  width: 16px;
  height: 16px;
  border-radius: 2px;
  border: 1px solid #cecece;
}
.swatch {
  padding: 4px;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: inline-block;
  cursor: pointer;
}
.colorPickerCover {
  position: fixed;
  width: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.sketch-picker {
  width: 170px !important;
  padding: 10px !important;
  margin: 10px 0 !important;
}
.colorPickerPopover {
  position: relative;
  z-index: 1;
}
.colorPickerWrapper {
  position: absolute;
  z-index: 100;
  top: -11px;
}
.displayMail {
  display: 'block';
}
.hideMail {
  display: 'none';
}
.mailList {
  margin: 5px 10px;
}
.mailsNav :global .ant-menu-inline {
  border: 0px !important;
  /* no unit */
}
.mailsNav :global .ant-card-bordered {
  border: 0px !important;
  /* no unit */
  border-left: 1px solid #e8e8e8 !important;
}
.mailsNav :global .ant-card {
  background-color: transparent;
}
.layoutOneMainNav :global .ant-menu-inline {
  border: 0px !important;
  /* no unit */
}
.layoutOneMainNav :global .ant-card-bordered {
  border: 0px !important;
  /* no unit */
}
.layoutOneMainNav :global .ant-card {
  background-color: transparent;
}
.mainMailLayout :global .ant-card-bordered {
  border: 0px !important;
  /* no unit */
}
.mainMailLayout :global .ant-card-body {
  padding: 0px;
  /* no unit */
}
.mainMailLayout :global h3 {
  margin-bottom: 0px;
  /* no unit */
}
.mainMailLayout :global .ant-card {
  background-color: transparent;
}
.mainMailNav :global .ant-card-bordered {
  border: 0px !important;
  /* no unit */
  border-right: 1px solid #e8e8e8 !important;
}
.mainMailNav :global .ant-card-body {
  padding: 1px;
}
.mainMailNav :global .ant-card {
  background-color: transparent;
}
.mainMailNav :global .ant-menu {
  background-color: transparent;
}
.mainMailNav :global .ant-collapse-borderless {
  background-color: transparent;
}
.layoutOneMainMailNav :global .ant-card-bordered {
  border: 0px !important;
  /* no unit */
}
.layoutOneMainMailNav :global .ant-card-body {
  padding: 0px 10px 0px 10px;
}
.layoutOneMainMailNav :global .ant-card {
  background-color: transparent;
}
.layoutOneMainMailNav :global .ant-menu {
  background-color: transparent;
}
.layoutOneMainMailNav :global .ant-collapse-borderless {
  background-color: transparent;
}
.layoutOneMainMailNav :global .ant-card-head {
  border: 0px;
  padding: 0 10px;
}
.layoutMainMail :global .ant-card-bordered {
  border: 0px !important;
  /* no unit */
}
.layoutMainMail :global .ant-card-body {
  padding: 0px;
  /* no unit */
  background-color: #fff;
  margin-top: 10px;
}
.layoutMainMail :global h3 {
  margin-bottom: 0px;
  /* no unit */
}
.layoutMainMail :global .ant-card {
  background-color: transparent;
}
.layoutMainMail :global .ant-card-head {
  border-bottom: 0px !important;
  background-color: #173e5f;
  color: '#fff';
  padding: 10px;
}
.layoutMainMail :global .ant-card-head-title {
  padding: 0px;
}
.mainContainer {
  background-color: #fff;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
}
.mainContainer :global .ant-card {
  background-color: transparent;
}
.composeMail {
  padding: 10px;
}
.composeMail :global .src-components-SimpleEditorToHtml-__index-module___demo-wrapper {
  margin-bottom: 0px !important;
  /* no unit */
  height: 314px !important;
}
.ant-tabs-tab {
  text-align: left !important;
}
.composed-btn {
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 2.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 2px;
}
.email-app {
  background-color: #fff;
}
.senderImage {
  width: 50px;
  float: left;
}
.mailActions {
  padding-left: 30px;
}
@media screen and (max-width: 992px) {
  .tableListForm :global(.ant-form-item) {
    margin-right: 24px;
  }
}
@media screen and (max-width: 768px) {
  .tableListForm :global(.ant-form-item) {
    margin-right: 8px;
  }
}
.baseTable :global .ant-table-pagination {
  margin-top: 24px;
}
.baseTable .tableAlert {
  margin-bottom: 16px;
}
.labelWork {
  background-color: #f62d51 !important;
  color: #fff !important;
}
.labelBusiness {
  background-color: #2dce89 !important;
  color: #fff !important;
}
.labelFamily {
  background-color: #fb6340 !important;
  color: #fff !important;
}
.labelFriend {
  background-color: #2962ff !important;
  color: #fff !important;
}
.starClassEnable {
  color: #000 !important;
}
.starClassDisable {
  color: #f1f1f1 !important;
}
.readMail :global .ant-list-item-meta-title {
  color: rgba(0, 0, 0, 0.65) !important;
}
.readMail :global .ant-list-item-meta-description {
  color: rgba(0, 0, 0, 0.45) !important;
}
.unreadMail :global .ant-list-item-meta-title {
  color: #000 !important;
}
.unreadMail :global .ant-list-item-meta-description {
  color: #000 !important;
}
/*
TAGS STYLING
*/
.restrictedSearch .tag-box input {
  display: none;
}
.restrictedSearch .tag-box .tag-box-pills {
  margin: 0;
}
.tag-box {
  width: 100%;
  position: relative;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 10px;
  padding: 6px 15px;
  border: 1px solid #e9e9e9;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
}
.tag-box .tag-box-pills {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-flow: row wrap;
}
.tag-box .tag-box-pills .tag-box-pill {
  display: flex;
  align-items: center;
  padding: 0 7px 0 10px;
  margin: 2px 5px 2px 0;
  height: 22px;
  word-break: break-word;
  background-color: #f5f5f5;
  border: 1px solid #d3d3d3;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
}
.tag-box .tag-box-pills .tag-box-pill .tag-box-pill-text {
  font-size: 13px;
  font-weight: 400;
  line-height: 1;
  color: #323232;
  padding-right: 7px;
}
.tag-box .tag-box-pills .tag-box-pill .remove {
  background: none;
  background-color: transparent;
  border: 0;
  outline: 0;
  color: #bababa;
  padding: 0;
  line-height: 1;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  -webkit-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -ms-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.tag-box .tag-box-pills .tag-box-pill .remove:hover {
  color: #888;
}
.tag-box input {
  font-size: 14px;
  font-weight: 400;
  color: #252424;
  line-height: inherit;
  height: 22px;
  padding: 0;
  border: 0;
  outline: 0 !important;
  overflow: hidden;
  background-color: #fff;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  -webkit-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -ms-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.tag-box .autocomplete {
  width: 100%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin: 3px 0 0;
  overflow: hidden;
  word-break: break-word;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
  text-align: left;
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 10;
}
.tag-box .autocomplete li {
  font-size: 14px;
  font-weight: 400;
  color: #323232;
  border-bottom: #e9e9e9;
  line-height: 1.5;
  width: 100%;
  padding: 10px 15px;
  margin: 0;
  cursor: pointer;
  -webkit-transition: all 0.35s ease;
  -moz-transition: all 0.35s ease;
  -ms-transition: all 0.35s ease;
  -o-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.tag-box .autocomplete li:hover {
  background-color: #f9f9f9;
}
.tag-box .autocomplete li:last-of-type {
  border-bottom: 0;
}
.moveBtn {
  width: 24%;
  float: left;
}
.exception {
  display: flex;
  align-items: center;
  height: 100%;
}
.exception .imgBlock {
  flex: 0 0 62.5%;
  width: 62.5%;
  padding-right: 152px;
  zoom: 1;
}
.exception .imgBlock:before,
.exception .imgBlock:after {
  content: ' ';
  display: table;
}
.exception .imgBlock:after {
  clear: both;
  visibility: hidden;
  font-size: 0;
  height: 0;
}
.exception .imgEle {
  height: 360px;
  width: 100%;
  max-width: 430px;
  float: right;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
}
.exception .content {
  flex: auto;
}
.exception .content h1 {
  color: #434e59;
  font-size: 72px;
  font-weight: 600;
  line-height: 72px;
  margin-bottom: 24px;
}
.exception .content .desc {
  color: #173f5f;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 16px;
}
.exception .content .actions button:not(:last-child) {
  margin-right: 8px;
}
@media screen and (max-width: 1200px) {
  .exception .imgBlock {
    padding-right: 88px;
  }
}
@media screen and (max-width: 576px) {
  .exception {
    display: block;
    text-align: center;
  }
  .exception .imgBlock {
    padding-right: 0;
    margin: 0 auto 24px;
  }
}
@media screen and (max-width: 480px) {
  .exception .imgBlock {
    margin-bottom: -24px;
    overflow: hidden;
  }
}
.popover {
  width: 336px;
}
.popover :global(.ant-popover-inner-content) {
  padding: 0;
}
.noticeButton {
  cursor: pointer;
  display: inline-block;
  transition: all 0.3s;
  margin: 0 10px;
}
.icon {
  font-size: 16px;
  padding: 4px;
}
.tabs :global .ant-tabs-nav-scroll {
  text-align: center;
}
.tabs :global .ant-tabs-bar {
  margin-bottom: 4px;
}
.bellIcon {
  color: #d5d5d5;
  font-size: 1.3em;
}
.ant-badge-multiple-words {
  padding: 0px !important;
}
.list {
  max-height: 400px;
  overflow: auto;
}
.list .item {
  transition: all 0.3s;
  overflow: hidden;
  cursor: pointer;
  padding-left: 24px;
  padding-right: 24px;
}
.list .item .meta {
  width: 100%;
}
.list .item .avatar {
  background: #fff;
  margin-top: 4px;
}
.list .item.read {
  opacity: 0.4;
}
.list .item:last-child {
  border-bottom: 0;
}
.list .item:hover {
  background: #8c989c;
}
.list .item .title {
  font-weight: normal;
  margin-bottom: 8px;
}
.list .item .description {
  font-size: 12px;
  line-height: 1.5;
}
.list .item .datetime {
  font-size: 12px;
  margin-top: 4px;
  line-height: 1.5;
}
.list .item .extra {
  float: right;
  color: rgba(0, 0, 0, 0.45);
  font-weight: normal;
  margin-right: 0;
  margin-top: -1.5px;
}
.notFound {
  text-align: center;
  padding: 73px 0 88px 0;
  color: rgba(0, 0, 0, 0.45);
}
.notFound img {
  display: inline-block;
  margin-bottom: 16px;
  height: 76px;
}
.clear {
  height: 46px;
  line-height: 46px;
  text-align: center;
  color: #2b2b2b;
  border-radius: 0 0 4px 4px;
  border-top: 1px solid #e8e8e8;
  transition: all 0.3s;
  cursor: pointer;
}
.clear:hover {
  color: #4f565e;
}
.profileMenu {
  display: inline-block;
}
.profileMenuIcon {
  border: 1px solid;
  font-size: 14px;
  border-radius: 11px;
}
.ant-menu-submenu .ant-menu-submenu-horizontal .ant-menu-submenu-open .ant-menu-submenu:active {
  border-bottom: 0px !important;
}
.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu {
  border-bottom: 0px !important;
}
.headerSearch .anticon-search {
  cursor: pointer;
  font-size: 1.3em;
  color: #d5d5d5;
}
.headerSearch .input {
  transition: width 0.3s, margin-left 0.3s;
  width: 0;
  background: transparent;
  border-radius: 0;
}
.headerSearch .input .ant-select-selection {
  background: transparent;
}
.headerSearch .input input {
  border: 0;
  padding-left: 0;
  padding-right: 0;
  box-shadow: none !important;
  color: #d5d5d5;
}
.headerSearch .input,
.headerSearch .input:hover,
.headerSearch .input:focus {
  border-bottom: 1px solid #d9d9d9;
}
.headerSearch .input.show {
  width: 210px;
  margin-left: 8px;
}
.headerSearch :global .ant-select-search__field {
  color: #d5d5d5 !important;
}
.ant-input-search-icon:hover {
  color: #d5d5d5;
}
.ant-input-search-icon {
  color: #d5d5d5;
}
.headerSearchIcon {
  font-size: 18px;
  color: #d5d5d5;
  padding-right: 10px;
}
.headerSearchInput .ant-input {
  background: #363636;
  color: #d5d5d5;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
}
.headerSearchInput .ant-input:focus {
  box-shadow: 0 0 0 0px rgba(23, 63, 95, 0.2) !important;
}
.headerSearchInput .ant-input-affix-wrapper .ant-input-prefix :not(.anticon),
.headerSearchInput .ant-input-affix-wrapper .ant-input-suffix :not(.anticon) {
  color: #d5d5d5;
}
.headerLanguageDropdown :global .ant-select-dropdown .ant-select-dropdown--single .ant-select-dropdown-placement-bottomLeft .ant-select-dropdown-hidden {
  width: 115px !important;
}
.result {
  text-align: center;
  width: 72%;
  margin: 0 auto;
}
.result .icon {
  font-size: 72px;
  line-height: 72px;
  margin-bottom: 24px;
}
.result .icon > .success {
  color: #52c41a;
}
.result .icon > .error {
  color: #f5222d;
}
.result .title {
  font-size: 24px;
  color: #4f565e;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 16px;
}
.result .description {
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 24px;
}
.result .extra {
  background: #fafafa;
  padding: 24px 40px;
  border-radius: 2px;
  text-align: left;
}
.result .actions {
  margin-top: 32px;
}
.result .actions button:not(:last-child) {
  margin-right: 8px;
}
.base .logo {
  height: 64px;
  position: relative;
  line-height: 64px;
  transition: all 0.3s;
  overflow: hidden;
}
.base .logo img {
  display: inline-block;
  vertical-align: middle;
  height: 64px;
}
.base .logo h1 {
  color: #d5d5de;
  display: inline-block;
  vertical-align: middle;
  font-size: 20px;
  margin: 0 0 0 12px;
  font-family: 'Myriad Pro', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: 400;
  text-transform: uppercase;
}
.base .sider {
  min-height: 100vh;
  position: relative;
  z-index: 10;
}
.base .icon {
  width: 14px;
  margin-right: 10px;
}
.base .ant-menu-dark.ant-menu-inline,
.base .ant-menu-dark.ant-menu-vertical,
.base .ant-menu-dark.ant-menu-vertical-left,
.base .ant-menu-dark.ant-menu-vertical-right {
  background: #414145;
}
.base .ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #59595e;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected {
  background: #353538;
}
.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  background: #59595e;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background: #353538;
}
@media only screen and (max-width: 768px) {
  .logo {
    padding-right: 12px;
    position: relative;
  }
}
.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-disabled,
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover {
  color: #d5d5de;
}
.base .ant-menu-dark .ant-menu-item,
.base .ant-menu-dark .ant-menu-item-group-title,
.base .ant-menu-dark .ant-menu-item a {
  color: #d5d5de;
}
.ant-menu-dark .ant-menu-item-disabled,
.ant-menu-dark .ant-menu-submenu-disabled,
.ant-menu-dark .ant-menu-item-disabled a,
.ant-menu-dark .ant-menu-submenu-disabled a {
  color: #d5d5de;
}
.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item a {
  color: #d5d5de;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  color: #d5d5de;
}
.rightHandle {
  position: absolute;
  top: 400px;
  right: 256px;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 48px;
  font-size: 32px;
  text-align: center;
  background: #423e3e;
  border-radius: 14px 0 0 14px;
  cursor: pointer;
  pointer-events: auto;
}
.pageTitleStyleOne {
  font-size: 32px;
  text-align: center;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.pageTitleStyleSecond {
  font-size: 24px;
  font-weight: 600;
}
.pageTitleStyleThree {
  font-size: 24px;
  padding-left: 24px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
.wrapHeaderStyle {
  display: flex;
  flex-wrap: nowrap;
  height: 53px;
  border: 1px solid;
  padding-bottom: 1px;
  cursor: pointer;
}
.ant-drawer-header {
  background: #423e3e;
}
.ant-drawer-title {
  color: #fff;
  text-align: center;
}
.themeDivider {
  margin: 15px 0 15px 0 !important;
}
.themeFont {
  font-size: 12px;
  letter-spacing: 4px;
  font-weight: 900;
}
.ant-drawer-body {
  font-size: 13px !important;
}
.themeDropdown {
  font-size: 13px !important;
}
.themeDrawerHeader .ant-drawer-header {
  border-radius: 0px !important;
}
.todosNav :global .ant-menu-inline {
  border: 0px !important;
  /* no unit */
}
.todosNav :global .ant-card-bordered {
  border: 0px !important;
  /* no unit */
}
.todosNav :global .ant-card {
  background-color: transparent;
}
.mainTodoLayout :global .ant-card-bordered {
  border: 0px !important;
  /* no unit */
}
.mainTodoLayout :global .ant-card-head {
  background-color: #00375B;
}
.mainTodoLayout :global .ant-card-body {
  padding: 10px;
  /* no unit */
}
.mainTodoLayout :global h3 {
  margin-bottom: 0px;
  /* no unit */
}
.mainTodoLayout :global .ant-card {
  background-color: transparent;
}
.mainTodoNav :global .ant-card-bordered {
  border: 0px !important;
  /* no unit */
}
.mainTodoNav :global .ant-card-body {
  padding: 1px;
}
.mainTodoNav :global .ant-card {
  background-color: transparent;
}
.mainTodoNav :global .ant-menu {
  background-color: transparent;
}
.mainTodoNav :global .ant-collapse-borderless {
  background-color: transparent;
}
.todosContainer {
  background-color: #fff;
  border: 0px !important;
}
.todosContainer :global .ant-card {
  background-color: transparent;
}
.todosList :global .ant-list-pagination {
  margin-top: 0px !important;
}
.backgroundClr {
  background-color: rgba(240, 242, 245, 0.849);
}
.msaSimpleBlogCard :global .ant-card {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 6px;
}
.msaAdvanceBlogCard :global .ant-card-cover {
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
}
.msaAdvanceBlogCard :global .ant-card-cover img {
  border-radius: 6px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.msaAdvanceBlogCard :global .ant-card {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  border: 1px solid #e8e8e805;
}
.msaFullBackgroundCard :global .ant-card-body {
  text-align: center;
}
.msaFullBackgroundCard :global .ant-card {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 6px;
}
.msaSimplePricingCard :global .ant-card-body {
  text-align: center;
}
.msaSimplePricingCard :global .ant-card {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 6px;
}
.msaAdvancePriceCard :global .ant-card-body {
  text-align: center;
}
.msaAdvancePriceCard :global .ant-list-item-content {
  display: block;
}
.msaAdvancePriceCard :global .ant-list {
  border-bottom: 1px solid #e8e8e8;
}
.msaAdvancePriceCard :global .ant-row {
  margin-right: 0px !important;
}
.msaAdvancePriceCard :global .ant-card {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 6px;
}
.msaProfileCardWithAvatar :global .ant-card-body {
  text-align: center;
}
.msaProfileCardWithAvatar :global .ant-card-cover {
  margin-top: 15px;
  text-align: center;
}
.msaProfileCardWithAvatar :global .ant-card-cover img {
  border-radius: 67px;
  max-width: 130px;
  max-height: 130px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.msaProfileCardWithAvatar :global .ant-card {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  border: 1px solid #e8e8e805;
}
.msaProfileCardWithCover :global .ant-card {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  border: 1px solid #e8e8e805;
}
.msaProfileCardWithCover :global .ant-card-cover {
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
}
.msaProfileCardWithCover :global .ant-card-cover img {
  border-radius: 6px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  max-width: 370px;
  max-height: 290px;
}
.msaProfileCardWithCover :global .ant-card-body {
  text-align: center;
}
.msaProfileCardWithAvatarSecond :global .ant-card-body {
  text-align: center;
  margin-top: 10px;
  padding: 5px !important;
}
.msaProfileCardWithAvatarSecond :global .ant-avatar {
  height: 70px;
  width: 70px;
  margin-top: 20px;
}
.msaProfileCardWithAvatarSecond :global .ant-card-cover {
  margin-top: 15px;
  text-align: center;
}
.msaProfileCardWithAvatarSecond :global .ant-card-cover img {
  border-radius: 67px;
  max-width: 130px;
  max-height: 130px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.msaProfileCardWithAvatarSecond :global .ant-card {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  border: 1px solid #e8e8e805;
}
.msaQuotesCard :global .ant-card-body {
  text-align: center;
}
.msaQuotesCard :global .ant-card {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  border: 1px solid #e8e8e805;
}
.msaRotatingCard {
  /* - Card container - */
  /* - Card body - */
  /* - Flipping the card - */
  /* - Card sides - */
  /* - Back side - */
}
.msaRotatingCard :global .ant-card {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  border: 1px solid #e8e8e805;
}
.msaRotatingCard .cardContainer {
  z-index: 1;
  perspective: 1000px;
}
.msaRotatingCard .cardBody {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: all 0.7s linear;
}
.msaRotatingCard .cardContainer:hover .cardBody {
  transform: rotateY(180deg);
}
.msaRotatingCard .sideFront :global .ant-card-body {
  text-align: center;
}
.msaRotatingCard .cardContainer:hover > .cardBody > .sideFront {
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s ease-in, visibility 0.75s linear;
}
.msaRotatingCard .cardSide {
  position: absolute;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  color: #212121;
  background-color: #fff;
  backface-visibility: hidden;
  box-shadow: 0 10px 35px rgba(50, 50, 93, 0.1), 0 2px 15px rgba(0, 0, 0, 0.07);
}
.msaRotatingCard .sideBack {
  z-index: 2;
  text-align: center;
  transform: rotateY(180deg);
}
.msaNavBar :global .ant-menu-horizontal {
  border-bottom: 0px solid #e8e8e808;
}
.msaNavBar :global .ant-menu {
  margin: 0px;
}
.msaNavBar :global .ant-menu-submenu-title:hover {
  color: #d5d5de;
}
.msaNavBar :global .ant-menu-submenu-horizontal:hover {
  border-bottom: 0px !important;
}
.msaNavBar .logoText {
  text-align: right;
  margin-top: 11px;
}
.trigger {
  color: #d5d5d5;
  cursor: pointer;
  font-size: 1.3em;
}
.msaStepForm .stepsContent {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  padding-top: 10px;
}
.msaOverview {
  max-width: 600px;
  border-right: 1px dashed #e9e9e9;
}
.msaOverview .userDetails {
  border-bottom: 1px dashed #e9e9e9;
  margin-bottom: 10px;
}
.msaOverview .userDetails .userDetailsTitle {
  padding: 20px;
  border-bottom: 1px dashed #e9e9e9;
}
.msaOverview .userDetails .userDetailsBody {
  margin-top: 20px;
}
.msaOverview .cardDetails .cardDetailsTitle {
  padding: 20px;
  border-bottom: 1px dashed #e9e9e9;
}
.msaOverview .cardDetails .cardDetailsBody {
  margin-top: 20px;
}
.msaStepFormFinish {
  text-align: center;
}
.msaStepFormFinish .icon {
  font-size: 72px;
  line-height: 72px;
  margin-bottom: 24px;
}
.msaStepFormFinish .icon > .success {
  color: #52c41a;
}
.msaStepFormFinish .icon > .error {
  color: #f5222d;
}
.msaStepFormFinish .title {
  font-size: 24px;
  color: #4f565e;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 16px;
}
.msaStepFormFinish .description {
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 24px;
}
.msaStepFormFinish .extra {
  background: #fafafa;
  padding: 24px 40px;
  border-radius: 2px;
  text-align: left;
}
.msaStepFormFinish .actions {
  margin-top: 32px;
}
.msaStepFormFinish .actions button:not(:last-child) {
  margin-right: 8px;
}
.msaTypographyWidget .typographyPrimary {
  color: #00375B;
}
.msaTypographyWidget .typographyInfo {
  color: #00acc1;
}
.msaTypographyWidget .typographySuccess {
  color: #52c41a;
}
.msaTypographyWidget .typographyWarning {
  color: #ff9800;
}
.msaTypographyWidget .typographyDanger {
  color: #f44336;
}
.msaTypographyWidget .typographyMuted {
  color: #6c757d;
}
.msaTypographyQuote {
  margin: 0 0 20px;
  padding: 10px 20px;
  font-size: 1.25rem;
  border-left: 5px solid #eee;
}
.msaTypographyQuote .quote {
  margin: 0 0 10px;
  font-style: italic;
}
.msaTypographyQuote .authorName {
  color: #777;
  display: block;
  font-size: 80%;
  line-height: 1.42857143;
}
.content {
  margin: 24px;
}
.activeTab :global .ant-tabs-bar .ant-tabs-tab {
  border-color: transparent;
  background: transparent;
}
.activeTab :global .ant-tabs-bar .ant-tabs-tab-active {
  border-color: #fff;
  background: #ddd237;
}
.pageHeader {
  background: #fff;
  padding: 16px 32px 0 32px;
  border-bottom: 1px solid #e8e8e8;
}
.pageHeader .detail {
  display: flex;
}
.pageHeader .row {
  display: flex;
}
.pageHeader .title {
  font-size: 20px;
  font-weight: 500;
  color: #00375B;
}
.pageHeader .action {
  margin-left: 56px;
  min-width: 266px;
}
.pageHeader .action :global .ant-btn-group:not(:last-child),
.pageHeader .action :global .ant-btn:not(:last-child) {
  margin-right: 8px;
}
.pageHeader .action :global .ant-btn-group > .ant-btn {
  margin-right: 0;
}
.pageHeader .title,
.pageHeader .action,
.pageHeader .content,
.pageHeader .extraContent,
.pageHeader .main {
  flex: auto;
}
.pageHeader .title,
.pageHeader .action {
  margin-bottom: 16px;
}
.pageHeader .logo,
.pageHeader .content,
.pageHeader .extraContent {
  margin-bottom: 16px;
}
.pageHeader .action,
.pageHeader .extraContent {
  text-align: right;
}
.pageHeader .extraContent {
  margin-left: 88px;
  min-width: 242px;
}
@media screen and (max-width: 1200px) {
  .pageHeader .extraContent {
    margin-left: 44px;
  }
}
@media screen and (max-width: 992px) {
  .pageHeader .extraContent {
    margin-left: 20px;
  }
}
@media screen and (max-width: 768px) {
  .pageHeader .row {
    display: block;
  }
  .pageHeader .action,
  .pageHeader .extraContent {
    margin-left: 0;
    text-align: left;
  }
}
@media screen and (max-width: 576px) {
  .content {
    margin: 24px;
  }
  .pageHeader .detail {
    display: block;
  }
}
@media screen and (max-width: 480px) {
  .pageHeader .action :global .ant-btn-group,
  .pageHeader .action :global .ant-btn {
    display: block;
    margin-bottom: 8px;
  }
  .pageHeader .action :global .ant-btn-group > .ant-btn {
    display: inline-block;
    margin-bottom: 0;
  }
}
.content {
  margin: 24px;
}
@media screen and (max-width: 576px) {
  .content {
    margin: 24px;
  }
}
.pageHeader {
  background: #fff;
  padding: 16px 24px;
  border-bottom: 1px solid #e8e8e8;
}
.pageHeader .title {
  font-size: 20px;
  font-weight: 500;
  color: #00375B;
  flex: auto;
  margin-bottom: 16px;
}
.pageTitleStyleOne {
  font-size: 32px;
  text-align: center;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  color: #00375B;
}
.pageTitleStyleSecond {
  font-size: 24px;
  font-weight: 600;
  color: #00375B;
}
.pageTitleStyleThree {
  font-size: 24px;
  color: #00375B;
  padding: 20px 0px 0px 24px;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
:global .ant-layout {
  min-height: 100vh;
  overflow-x: hidden;
}
:global .ant-layout .ant-layout-header {
  padding: 0 !important;
}
:global .ant-layout .ant-layout-content {
  min-height: unset;
}
:global .ant-layout .ant-layout-footer {
  padding: 0 !important;
}
.userLayoutContainer {
  background: #f0f2f5;
  position: relative;
  height: 100vh;
  width: 100vw;
}
.userLayoutContainer:before {
  content: '';
  background-color: #363636;
  width: 100vw;
  height: 30%;
  box-shadow: 1px 2px 3px 1px rgba(0, 0, 0, 0.3);
  position: absolute;
  left: 0px;
  top: 0px;
}
.contentGrid {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  flex-direction: column;
}
.contentUserLayout {
  padding: 20px;
  background-color: #fff;
  margin: 0px;
  border: 1px solid #ddd;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.2);
}
.top {
  text-align: center;
}
.userLayoutHeader a {
  text-decoration: none;
  display: block;
}
.userLayoutLogo {
  height: 70px;
  vertical-align: middle;
}
.title {
  font-size: 25px;
  color: #00375B;
  font-family: 'Myriad Pro', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: 400;
  position: relative;
  top: 2px;
  margin-bottom: 20px;
}
.desc {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  margin-top: 12px;
  margin-bottom: 40px;
}
.container {
  background: #f0f2f5;
  position: relative;
  height: 100vh;
  width: 100vw;
}
.loginBar {
  width: 100vw;
  height: 20vh;
  background-color: transparent;
}
.contentUserLayout1 {
  width: 100vw;
  height: 60vh;
  background-color: #fff;
}
.help {
  width: 50vw;
  height: 60vh;
  background-image: url('/images/login-image.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;
}
.help:before {
  content: '';
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
}
.form {
  width: 50vw;
  height: 60vh;
}
.header a {
  text-decoration: none;
  display: block;
  text-align: center;
}
.logo {
  height: 70px;
}
.container {
  background: #fff;
  height: 100vh;
  width: 100vw;
}
.loginImage {
  width: 65vw;
  height: 100vh;
  background-image: url('/images/login-image.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;
}
.loginImage:before {
  content: '';
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
}
.form {
  width: 35vw;
  height: 100vh;
}
.header a {
  text-decoration: none;
  display: block;
  text-align: center;
}
.logo {
  height: 70px;
}
.footer {
  height: 10vh;
}
.tableList .tableListOperator {
  margin-bottom: 16px;
  margin-top: 16px;
}
.tableList .tableListOperator button {
  margin-right: 8px;
}
.tableListForm :global .ant-form-item {
  margin-bottom: 24px;
  margin-right: 0;
  display: flex;
}
.tableListForm :global .ant-form-item > .ant-form-item-label {
  width: auto;
  line-height: 32px;
  padding-right: 8px;
}
.tableListForm :global .ant-form-item-control-wrapper {
  flex: 1;
}
.tableListForm :global .ant-select-selection {
  max-height: 51px;
  overflow: auto;
  background-color: orangered;
}
.tableListForm .submitButtons {
  white-space: nowrap;
  margin-bottom: 24px;
}
.tableListForm .submitButtons :global .ant-btn-primary:hover,
.tableListForm .submitButtons :global .ant-btn-primary:focus,
.tableListForm .submitButtons :global .ant-btn-primary:active,
.tableListForm .submitButtons :global .ant-btn-primary.active {
  background-color: yellowgreen;
}
.tableListForm .submitButtons :global .ant-btn-primary {
  background-color: orangered;
}
@media screen and (max-width: 992px) {
  .tableListForm :global(.ant-form-item) {
    margin-right: 24px;
  }
}
@media screen and (max-width: 768px) {
  .tableListForm :global(.ant-form-item) {
    margin-right: 8px;
  }
}
.tableList .tableListOperator {
  margin-bottom: 16px;
  margin-top: 16px;
}
.tableList .tableListOperator button {
  margin-right: 8px;
}
.tableListForm :global .ant-form-item {
  margin-bottom: 24px;
  margin-right: 0;
  display: flex;
}
.tableListForm :global .ant-form-item > .ant-form-item-label {
  width: auto;
  line-height: 32px;
  padding-right: 8px;
}
.tableListForm :global .ant-form-item-control-wrapper {
  flex: 1;
}
.tableListForm :global .ant-select-selection {
  max-height: 51px;
  overflow: auto;
  background-color: orangered;
}
.tableListForm .submitButtons {
  white-space: nowrap;
  margin-bottom: 24px;
}
.tableListForm .submitButtons :global .ant-btn-primary:hover,
.tableListForm .submitButtons :global .ant-btn-primary:focus,
.tableListForm .submitButtons :global .ant-btn-primary:active,
.tableListForm .submitButtons :global .ant-btn-primary.active {
  background-color: yellowgreen;
}
.tableListForm .submitButtons :global .ant-btn-primary {
  background-color: orangered;
}
@media screen and (max-width: 992px) {
  .tableListForm :global(.ant-form-item) {
    margin-right: 24px;
  }
}
@media screen and (max-width: 768px) {
  .tableListForm :global(.ant-form-item) {
    margin-right: 8px;
  }
}
.events {
  line-height: 24px;
  list-style: none;
  margin: 0;
  padding: 0;
}
.events li {
  color: #00375B;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.events li span {
  vertical-align: middle;
}
.events li span:first-child {
  font-size: 9px;
  margin-right: 4px;
}
.events .important {
  color: #fac450;
}
.events .normal {
  color: #108ee9;
}
.events .must {
  color: #f50;
}
.events .notesMonth {
  text-align: center;
}
.events .notesMonth section {
  font-size: 28px;
}
.activitiesList {
  padding: 0 24px 8px 24px;
}
.activitiesList .username {
  color: #2b2b2b;
}
.activitiesList .event {
  font-weight: normal;
}
.pageHeaderContent {
  display: flex;
}
.pageHeaderContent .avatar {
  flex: 0 1 72px;
  margin-bottom: 8px;
}
.pageHeaderContent .avatar > span {
  border-radius: 72px;
  display: block;
  width: 72px;
  height: 72px;
}
.pageHeaderContent .content {
  position: relative;
  top: 4px;
  margin-left: 24px;
  flex: 1 1 auto;
  color: rgba(0, 0, 0, 0.45);
  line-height: 22px;
}
.pageHeaderContent .content .contentTitle {
  font-size: 20px;
  line-height: 28px;
  font-weight: 500;
  color: #4f565e;
  margin-bottom: 12px;
}
.pageHeaderExtra {
  zoom: 1;
  float: right;
}
.pageHeaderExtra:before,
.pageHeaderExtra:after {
  content: " ";
  display: table;
}
.pageHeaderExtra:after {
  clear: both;
  visibility: hidden;
  font-size: 0;
  height: 0;
}
.pageHeaderExtra > div {
  padding: 0 32px;
  position: relative;
  float: left;
}
.pageHeaderExtra > div > p:first-child {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 4px;
}
.pageHeaderExtra > div > p {
  color: #4f565e;
  font-size: 30px;
  line-height: 38px;
  margin: 0;
}
.pageHeaderExtra > div > p > span {
  color: rgba(0, 0, 0, 0.45);
  font-size: 20px;
}
.pageHeaderExtra > div:after {
  position: absolute;
  top: 8px;
  right: 0;
  width: 1px;
  height: 40px;
  content: '';
}
.pageHeaderExtra > div:last-child {
  padding-right: 0;
}
.pageHeaderExtra > div:last-child:after {
  display: none;
}
.members a {
  display: block;
  margin: 12px 0;
  line-height: 24px;
  height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
}
.members a .member {
  font-size: 14px;
  color: #2b2b2b;
  line-height: 24px;
  max-width: 100px;
  vertical-align: top;
  margin-left: 12px;
  transition: all 0.3s;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
}
.members a:hover span {
  color: #00375B;
}
.projectList :global .ant-card-meta-description {
  color: rgba(0, 0, 0, 0.45);
  height: 44px;
  line-height: 22px;
  overflow: hidden;
}
.projectList .cardTitle {
  font-size: 0;
}
.projectList .cardTitle a {
  color: #4f565e;
  margin-left: 12px;
  line-height: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
}
.projectList .cardTitle a:hover {
  color: #00375B;
}
.projectList .projectGrid {
  width: 33.33%;
}
.projectList .projectItemContent {
  display: flex;
  margin-top: 8px;
  font-size: 12px;
  height: 20px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
}
.projectList .projectItemContent a {
  color: rgba(0, 0, 0, 0.45);
  display: inline-block;
  flex: 1 1 0;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
}
.projectList .projectItemContent a:hover {
  color: #00375B;
}
.projectList .projectItemContent .datetime {
  color: rgba(0, 0, 0, 0.25);
  flex: 0 0 auto;
  float: right;
}
.datetime {
  color: rgba(0, 0, 0, 0.25);
}
.analyticTop {
  margin-top: 10px;
}
.analyticTop :global .ant-card-body {
  padding: 5px 20px;
}
.analyticTopOrder :global .ant-card-body {
  padding: 7px 20px;
}
.analyticTopSale :global .ant-card-body {
  padding: 7px 20px;
}
.todayTransaction :global .ant-progress-bg {
  background-color: #2777b6 !important;
}
.TransactionPerSec :global .ant-progress-bg {
  background-color: #a7b4b9 !important;
}
.salesTarget :global .ant-progress-bg {
  background-color: #78b47c !important;
}
.monitorSales :global .ant-card-body {
  padding: 39px;
}
.monitorSaleBar {
  margin-top: 20px;
}
.popularSearches :global .ant-tag {
  margin: 5px;
}
.popularSearches :global .ant-card-body {
  padding: 30px;
}
.ecommerce .salesGraph :global .ant-card.ant-card-wider-padding {
  padding-left: 0px !important;
  /* no unit */
  padding-right: 0px !important;
  /* no unit */
}
.ecommerce .salesGraph :global .ant-col-16 {
  padding-left: 3px !important;
  padding-right: 7px !important;
}
.ecommerce .salesGraph :global .ant-card-actions {
  background: #fff;
}
.ecommerce .salesGraph :global .ant-card-actions li {
  text-align: left !important;
}
.ecommerce .salesGraph :global .ant-select {
  margin-left: 10px;
}
.ecommerce .selectFilter {
  margin-top: 10px;
}
.ecommerce .selectFilter :global .ant-card-actions {
  background: #fff;
}
.ecommerce .selectFilter :global .ant-card-actions li {
  text-align: left !important;
}
.ecommerce .selectFilter :global .ant-select {
  margin-left: 10px;
}
.ecommerce .positiveReviews :global .ant-progress-bg {
  background-color: #5bb75b;
}
.ecommerce .negativeReviews :global .ant-progress-bg {
  background-color: #d9534d;
}
.ecommerce .neutralReviews :global .ant-progress-bg {
  background-color: #3179b8;
}
.ecommerce .averageOrder {
  text-align: center;
}
.ecommerce .averageOrder h1 {
  color: #d9534d;
  font-size: 64px;
}
.analytics .mobileSession :global .ant-card-body {
  padding: 40px;
}
.analytics #reactgooglegraph-1 {
  padding-left: 58px;
}
.analytics .analyticsCard :global .ant-card-actions {
  background: #fff;
}
.analytics .analyticsCard :global .ant-card-actions li {
  text-align: left !important;
}
.analytics .analyticsCard :global .ant-select {
  margin-left: 10px;
}
.analytics .websitePerformance {
  border-top: 1px solid #e8e4e4;
}
.analytics .websitePerformance :global .ant-card-body {
  padding: 15px;
}
.saas .saasCard :global .ant-card-actions {
  background: #fff;
}
.saas .saasCard :global .ant-card-actions li {
  text-align: left !important;
}
.saas .saasCard :global .ant-select {
  margin-left: 10px;
}
.saas .saasGauge {
  margin-top: 20px;
}
.socialMedia .socialMediaCard :global .ant-card-actions {
  background: #fff;
}
.socialMedia .socialMediaCard :global .ant-card-actions li {
  text-align: left !important;
}
.socialMedia .socialMediaCard :global .ant-select {
  margin-left: 10px;
}
.socialMedia .facebookPageStats {
  margin-top: 20px;
  min-height: 525px;
}
.socialMedia .twitterStats {
  min-height: 525px;
}
.ecommerceStats .monthlyIncomeBar :global .ant-progress-bg {
  background-color: #327ab7;
}
.ecommerceStats .monthlyIncomeBar :global .ant-card-body {
  height: 146px;
}
.ecommerceStats .monthlySales :global .ant-card-body {
  padding: 34px;
}
.ecommerceStats .newCustomers :global .ant-card-body {
  padding: 24px;
}
@media screen and (min-width: 1200px) {
  .pageHeaderExtra {
    min-width: 345px;
  }
}
@media screen and (max-width: 1200px) and (min-width: 992px) {
  .activeCard {
    margin-bottom: 24px;
  }
  .members {
    margin-bottom: 0;
  }
  .pageHeaderExtra {
    margin-left: -44px;
  }
  .pageHeaderExtra > div {
    padding: 0 16px;
  }
}
@media screen and (max-width: 992px) {
  .activeCard {
    margin-bottom: 24px;
  }
  .members {
    margin-bottom: 0;
  }
  .pageHeaderExtra {
    float: none;
    margin-right: 0;
  }
  .pageHeaderExtra > div {
    padding: 0 16px;
    text-align: left;
  }
  .pageHeaderExtra > div:after {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .pageHeaderExtra {
    margin-left: -16px;
  }
  .projectList .projectGrid {
    width: 50%;
  }
}
@media screen and (max-width: 576px) {
  .pageHeaderContent {
    display: block;
  }
  .pageHeaderContent .content {
    margin-left: 0;
  }
  .pageHeaderExtra > div {
    float: none;
  }
}
@media screen and (max-width: 480px) {
  .projectList .projectGrid {
    width: 100%;
  }
}
.hello {
  background: #414145;
}
.componentsAvatarDemo :global .ant-avatar {
  margin-top: 16px;
  margin-right: 16px;
}
.componentsBackTopDemo :global(.ant-back-top) {
  bottom: 100px;
}
.componentsBackTopDemo :global(.ant-back-top-inner) {
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 4px;
  background-color: #1088e9;
  color: #fff;
  text-align: center;
  font-size: 20px;
}
.componentsBadgeDemo :global(.ant-badge:not(.ant-badge-status)) {
  margin-right: 20px;
}
.componentsBadgeDemo .headExample {
  width: 42px;
  height: 42px;
  border-radius: 4px;
  background: #eee;
  display: inline-block;
}
.componentsBreadcrumbDemo .demo {
  margin: 16px;
}
.componentsBreadcrumbDemo .demo-nav {
  height: 30px;
  line-height: 30px;
  margin-bottom: 16px;
  background: #f8f8f8;
}
.componentsBreadcrumbDemo .demo-nav a {
  line-height: 30px;
  padding: 0 8px;
}
.componentsBreadcrumbDemo .app-list {
  margin-top: 16px;
}
.browser-mockup {
  border-top: 2em solid rgba(230, 230, 230, 0.7);
  -webkit-box-shadow: 0 0.1em 0.5em 0 rgba(0, 0, 0, 0.28);
  box-shadow: 0 0.1em 0.5em 0 rgba(0, 0, 0, 0.28);
  position: relative;
  border-radius: 3px 3px 0 0;
}
.browser-mockup:before {
  display: block;
  position: absolute;
  content: '';
  top: -1.25em;
  left: 1em;
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  background-color: #f44;
  -webkit-box-shadow: 0 0 0 2px #f44, 1.5em 0 0 2px #9b3, 3em 0 0 2px #fb5;
  box-shadow: 0 0 0 2px #f44, 1.5em 0 0 2px #9b3, 3em 0 0 2px #fb5;
}
.browser-mockup.with-tab:after {
  display: block;
  position: absolute;
  content: '';
  top: -2em;
  left: 5.5em;
  width: 20%;
  height: 0;
  border-bottom: 2em solid white;
  border-left: 0.8em solid transparent;
  border-right: 0.8em solid transparent;
}
.browser-mockup.with-url:after {
  display: block;
  position: absolute;
  content: '';
  top: -1.6em;
  left: 5.5em;
  width: calc(100% - 6em);
  height: 1.2em;
  border-radius: 2px;
  background-color: white;
}
.browser-mockup > * {
  display: block;
}
.componentsButtonDemo :global .ant-btn {
  margin-right: 8px;
  margin-bottom: 12px;
}
.componentsButtonDemo :global .ant-btn-group {
  margin-right: 8px;
}
.componentsButtonDemo :global .ant-btn-group > .ant-btn {
  margin-right: 0;
}
.btnColor {
  background-color: #173f5f !important;
  color: #fff !important;
}
.btnRadius {
  background-color: #52c41a !important;
  border-radius: 25px 25px !important;
  color: #fff !important;
}
.btnStyle {
  border-bottom-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  background-color: #ff9800 !important;
  color: #fff !important;
}
.events {
  line-height: 24px;
  list-style: none;
  margin: 0;
  padding: 0;
}
.events li {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.events li span {
  vertical-align: middle;
}
.events li span:first-child {
  font-size: 9px;
  margin-right: 4px;
}
.event-warning {
  color: #fac450;
}
.event-normal {
  color: #108ee9;
}
.event-error {
  color: #f50;
}
.notes-month {
  text-align: center;
}
.notes-month section {
  font-size: 28px;
}
.custom-image img {
  display: block;
}
.custom-card {
  padding: 10px 16px;
}
.custom-card p {
  color: #999;
}
.ant-carousel .slick-slide {
  text-align: center;
  height: 160px;
  line-height: 160px;
  background: #364d79;
  overflow: hidden;
}
.ant-carousel .slick-slide h3 {
  color: #fff;
}
.datePickerDemo .ant-calendar-picker {
  margin: 0 8px 12px 0;
}
.login-form {
  max-width: 300px;
}
.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}
.code-box-demo .ant-form:not(.ant-form-inline):not(.ant-form-vertical) {
  max-width: 540px;
}
.anticon-close-circle {
  cursor: pointer;
  color: #ccc;
  transition: color 0.3s;
  font-size: 12px;
}
.anticon-close-circle:hover {
  color: #999;
}
.anticon-close-circle:active {
  color: #666;
}
.numeric-input .ant-tooltip-inner {
  min-width: 32px;
  min-height: 37px;
}
.numeric-input .numeric-input-title {
  font-size: 14px;
}
.example-input :global .ant-input {
  width: 200px;
  margin: 0 8px 8px 0;
}
.exampleForth :global .ant-input:focus {
  box-shadow: 0 0 0 0px rgba(23, 63, 95, 0.2) !important;
}
#components-layout-demo-top-side-2 .logo {
  width: 120px;
  height: 31px;
  background: #333;
  border-radius: 6px;
  margin: 16px 28px 16px 0;
  float: left;
}
#components-layout-demo-side .logo {
  height: 32px;
  background: #333;
  border-radius: 6px;
  margin: 16px;
}
/* use css to set position of modal */
.vertical-center-modal {
  text-align: center;
  white-space: nowrap;
}
.vertical-center-modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  width: 0;
}
.vertical-center-modal .ant-modal {
  display: inline-block;
  vertical-align: middle;
  top: 0;
  text-align: left;
}
.code-box-demo .ant-select {
  margin: 0 8px 10px 0;
}
#components-select-demo-search-box .code-box-demo .ant-select {
  margin: 0;
}
.icon-wrapper {
  position: relative;
  padding: 0px 30px;
}
.icon-wrapper .anticon {
  position: absolute;
  top: -3px;
  width: 16px;
  height: 16px;
  line-height: 1;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.25);
}
.icon-wrapper .anticon:first-child {
  left: 0;
}
.icon-wrapper .anticon:last-child {
  right: 0;
}
.anticon.anticon-highlight {
  color: #666;
}
.example {
  text-align: center;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-bottom: 20px;
  padding: 30px 50px;
  margin: 20px 0;
}
.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  text-align: center;
  padding-top: 80px;
}
.steps-action {
  margin-top: 24px;
}
.card-container :global .ant-tabs-content {
  height: 120px;
  margin-top: -16px;
}
.card-container :global .ant-tabs-content .ant-tabs-content .ant-tabs-tabpane {
  background: #fff;
  padding: 16px;
}
.card-container :global .ant-tabs-card .ant-tabs-content .ant-tabs-tabpane {
  background: #fff;
  padding: 16px;
}
.card-container :global .ant-tabs-card .ant-tabs-bar {
  border-color: #fff;
}
.card-container :global .ant-tabs-card .ant-tabs-bar .ant-tabs-tab {
  border-color: transparent;
  background: transparent;
}
.card-container :global .ant-tabs-card .ant-tabs-bar .ant-tabs-tab-active {
  border-color: #fff;
  background: #fff;
}
.ant-tag {
  margin-bottom: 8px;
}
.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}
.upload-list-inline .ant-upload-animate-enter {
  animation-name: uploadAnimateInlineIn;
}
.upload-list-inline .ant-upload-animate-leave {
  animation-name: uploadAnimateInlineOut;
}
.upload-demo-start {
  margin-top: 16px;
}
.avatar-uploader,
.avatar-uploader-trigger,
.avatar {
  width: 150px;
  height: 150px;
}
.avatar-uploader {
  display: block;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
}
.avatar-uploader-trigger {
  display: table-cell;
  vertical-align: middle;
  font-size: 28px;
  color: #999;
}
.recoverHelpText {
  font-size: 0.7rem;
  text-align: center;
  line-height: 0.5;
}
.recoverHelpText .forgotHighlight {
  font-weight: 600;
}
.forgotCover {
  width: 300px;
  margin: 0 auto;
}
.forgotCover .forgotForm {
  width: 100%;
}
.forgotCover .forgotForm .forgotSubmit {
  width: 100%;
}
.userProfile {
  height: 200px;
  width: 100px;
  margin-bottom: 10px;
}
.lockScreen {
  text-align: center;
}
.unlockButton {
  background: #173f5f;
  color: #fff;
}
.lockScreenCover {
  width: 300px;
  margin: 0 auto;
}
.lockScreenCover .lockScreenForm {
  width: 100%;
}
.loginCover {
  width: 300px;
  margin: 0 auto;
}
.loginCover .loginForm {
  width: 100%;
}
.loginCover .loginForm .loginForgot {
  float: right;
}
.loginCover .loginForm .loginSubmit {
  width: 100%;
}
.loginCover .loginHelpText {
  font-size: 0.7rem;
  text-align: center;
  line-height: 0.5;
}
.loginCover .loginHelpText .loginHighlight {
  font-weight: 600;
}
.loginCover .loginActions {
  text-align: center;
}
.registerCover {
  width: 300px;
  margin: 0 auto;
}
.registerCover .registerForm {
  width: 100%;
}
.registerCover .registerForm .registerSubmit {
  width: 100%;
}
.registerCover .registerActions {
  text-align: center;
}
.resetCover {
  width: 300px;
  margin: 0 auto;
}
.resetCover .resetForm {
  width: 100%;
}
.resetCover .resetForm .resetSubmit {
  width: 100%;
}
body {
  position: fixed;
  overflow: hidden;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 1px;
}
